<template>
  <c-grid class="gridaside" @grid:created="$emit('grid:created')" @grid:mounted="onGridMount" @grid:beforeDestroy="$emit('grid:beforeDestroy')">
    <template #header>
      <slot name="header"/>
    </template>

    <b-row>
      <b-col :[$root.mobile.up.key]="3" tag="aside">
        <div :class="['gridaside-aside', { 'is-open': aside.open }]">
          <div>
            <div class="aside-header">
              <b-row align-v="center" align-h="between">
                <b-col cols="auto"></b-col>

                <b-col cols="auto">
                  <b-button class="aside-toggle" variant="link" @click="toggle(false)"><b-icon icon="close"/></b-button>
                </b-col>
              </b-row>
            </div>

            <div class="aside-body">
              <scrollbar :options="{ suppressScrollX: true }">
                <div v-if="enableSearch" class="gridaside-search">
                  <control-input
                    id="AsideSearch"
                    class="search-control"
                    :label="searchLabel"
                    v-model="search.query"
                    @input="searchProcess"
                    @keyup.native.enter="toggle(false)"
                  >
                    <template #append="">
                      <b-button variant="link" class="search-button" @click="toggle(false)"><b-icon class="button-icon" icon="search"/></b-button>
                    </template>
                  </control-input>

                  <b-form-row class="justify-content-between">
                    <b-col cols="auto">
                      <b-button class="search-reset" variant="link" :disabled="disableReset" @click="reset">{{ searchResetText }}</b-button>
                    </b-col>
                    <b-col cols="auto">
                      <div class="search-count">{{ searchResultCount && !search.query ? searchResultCount:search.results.length }} {{ searchCountText }}</div>
                    </b-col>
                  </b-form-row>
                </div>

                <slot name="aside"/>
              </scrollbar>
            </div>
          </div>
        </div>
      </b-col>

      <b-col :[$root.mobile.up.key]="9" tag="article">
        <b-button class="aside-toggle" block variant="primary" @click="toggle(true)"><b-icon v-if="toggleIcon" :icon="toggleIcon"/> {{ toggleText }}</b-button>

        <template v-if="enableSearch">
          <h3 class="search-count">{{ searchResultCount && !search.query ? searchResultCount:search.results.length }} {{ searchCountText }}</h3>
          <b-button class="search-reset" variant="link" :disabled="disableReset" @click="reset"><b-icon icon="reset"/> {{ searchResetText }}</b-button>
          <p v-if="search.results.length <= 0" class="search-noresults">{{ searchNoresultText }}</p>
        </template>

        <b-row class="gridaside-row" ref="content" :cols="contentCols.xs" :cols-sm="contentCols.sm" :cols-md="contentCols.md" :cols-lg="contentCols.lg" :cols-xl="contentCols.xl">
          <b-col v-for="sKey in contentSlotKeys" :key="sKey" :ref="sKey" v-show="search.results.includes(sKey)" class="gridaside-col">
            <slot :name="sKey"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <template #footer>
      <slot name="footer"/>
    </template>
  </c-grid>
</template>

<script>
import { SINGLE_COLUMN_WIDTHS, ASIDE_COLUMN_WIDTHS } from '@/assets/js/config/client'

import Scrollbar from '@/components/Scrollbar'

const EXCLUDE_SCOPED_SLOTS = ['header', 'aside', 'footer']

export default {
  name: 'GridAside',
  components: {
    Scrollbar
  },
  props: {
    toggleText: {
      type: String,
      default: ''
    },
    toggleIcon: {
      type: [Boolean, String],
      default: 'filter'
    },
    enableSearch: {
      type: Boolean,
      default: false
    },
    searchLabel: {
      type: String,
      default: ''
    },
    searchQuery: {
      type: String,
      default: ''
    },
    searchQuantityText: {
      type: [String, Array],
      default: ''
    },
    searchResetText: {
      type: String,
      default: ''
    },
    searchNoresultText: {
      type: String,
      default: ''
    },
    multiCols: {
      type: Boolean,
      default: false
    },
    searchResultCount: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      aside: {
        open: false
      },
      search: {
        query: this.searchQuery,
        results: [],
        observer: null
      }
    }
  },
  computed: {
    contentCols () {
      return this.multiCols ? ASIDE_COLUMN_WIDTHS : SINGLE_COLUMN_WIDTHS
    },
    contentSlotKeys () {
      return Object.keys(this.$scopedSlots || {})
        .filter(sKey => !EXCLUDE_SCOPED_SLOTS.includes(sKey))
    },
    disableReset () {
      return this.search.query === ''
    },
    searchCountText () {
      const quantityTexts = [].concat(this.searchQuantityText)
      return quantityTexts[Math.min(this.search.results.length, 2)] || ''
    }
  },
  methods: {
    onGridMount () {
      this.$emit('grid:mounted')
      this.setupObserver()
    },
    setupObserver () {
      this.searchProcess()
      this.search.observer = new MutationObserver(this.searchProcess)
      this.search.observer.observe(this.$refs.content, { childList: true, subtree: true })
    },
    toggle (state) {
      this.aside.open = state !== undefined ? state : !this.aside.open
      this.$root.$emit('scrollbar:toggle', { key: this.$options.name, state: this.aside.open, till: this.$root.mobile.down.pixel })
    },
    reset () {
      this.$emit('gridaside:reset')
      this.searchReset()
    },
    searchProcess () {
      const query = this.search.query.toLowerCase().trim()

      this.search.results = []

      if (query === '') {
        this.searchReset()
      } else {
        this.contentSlotKeys
          .forEach(sKey => {
            const ref = (this.$refs[sKey] || [{}])[0]
            const text = (ref.innerText || '').toLowerCase()
            const matches = query.split(' ').every(q => text.indexOf(q) >= 0)

            if (matches) this.search.results.push(sKey)
          })
      }
    },
    searchReset () {
      this.search.query = ''
      this.search.results = this.contentSlotKeys
    }
  },
  mounted () {
    setTimeout(() => {
      this.search.observer.disconnect()
    }, 1000)
  }
}
</script>

<style lang="scss">
$gridaside-aside-bg: transparent !default;

$gridaside-search-gap: $spacer * 1.5 !default;
$gridaside-search-control-gap: $spacer * 0.25 !default;
$gridaside-search-control-icon-color: $primary !default;
$gridaside-search-reset-gap: 0 !default;
$gridaside-search-reset-font-size: $font-size-sm * 0.8 !default;
$gridaside-search-count-gap: $spacer !default;
$gridaside-search-count-font-size: inherit !default;
$gridaside-search-noresults-color: $red !default;

$gridaside-row-gap: $grid-gutter-width !default;

$gridaside-mobile-breakpoint: $mobile-breakpoint !default;

$gridaside-mobile-aside-toggle-gap: $spacer !default;

$gridaside-mobile-aside-bg: $white !default;
$gridaside-mobile-aside-transition-in-duration: 400ms !default;
$gridaside-mobile-aside-transition-in: transform cubic-bezier(0.35, 0.05, 0.4, 1) $gridaside-mobile-aside-transition-in-duration, opacity ease-in ($gridaside-mobile-aside-transition-in-duration - 50ms) 50ms !default;
$gridaside-mobile-aside-transition-out-duration: 250ms !default;
$gridaside-mobile-aside-transition-out: transform cubic-bezier(0.35, 0.05, 0.4, 1) $gridaside-mobile-aside-transition-out-duration, opacity ease-out ($gridaside-mobile-aside-transition-out-duration - 50ms) !default;

$gridaside-mobile-aside-header-padding-y: $component-padding-y * 0.5 !default;
$gridaside-mobile-aside-header-padding-x: 0 !default;

$gridaside-mobile-aside-body-padding-y: $component-padding-y * 0.5 !default;
$gridaside-mobile-aside-body-padding-x: 0 !default;

$gridaside-mobile-search-reset-gap: 0 !default;
$gridaside-mobile-search-reset-font-size: $font-size-base * 0.75 !default;
$gridaside-mobile-search-count-gap: 0 !default;
$gridaside-mobile-search-count-font-size: $gridaside-mobile-search-reset-font-size !default;

.gridaside {
  .gridaside-search {
    margin-bottom: $gridaside-search-gap;

    .search-control {
      margin-bottom: $gridaside-search-control-gap;

      .search-button {
        .button-icon {
          color: $gridaside-search-control-icon-color;
        }
      }
    }
  }

  .search-reset {
    display: none;
    margin-bottom: $gridaside-search-reset-gap;
    padding: 0;
    font-size: $gridaside-search-reset-font-size;
  }

  .search-count {
    margin-bottom: $gridaside-search-count-gap;
    font-size: $gridaside-search-count-font-size;
  }

  .search-noresults {
    margin: 0;
    color: $gridaside-search-noresults-color;
  }

  .aside-toggle {
    display: none;
  }

  .gridaside-aside {
    background-color: $gridaside-aside-bg;

    .search-count {
      display: none;
    }

    .search-reset {
      display: block;
    }
  }

  .gridaside-row {
    margin-top: $gridaside-row-gap * -0.5;
    margin-bottom: $gridaside-row-gap * -0.5;

    .gridaside-col {
      margin-top: $gridaside-row-gap * 0.5;
      margin-bottom: $gridaside-row-gap * 0.5;
    }
  }

  @include media-breakpoint-down($gridaside-mobile-breakpoint) {
    .search-reset {
      margin-bottom: $gridaside-mobile-search-reset-gap;
      font-size: $gridaside-mobile-search-reset-font-size;
    }

    .aside-toggle {
      display: block;
      margin-bottom: $gridaside-mobile-aside-toggle-gap;
    }

    .gridaside-aside {
      position: fixed;
      top: $mainnavigation-mobile-height;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $gridaside-mobile-aside-bg;
      opacity: 0;
      z-index: 2000;
      transform: translateX(-100%);
      transition: $gridaside-mobile-aside-transition-out;

      > div {
        @include make-container();
        @include make-container-max-widths();
        display: flex;
        flex-direction: column;
        height: 100%;

        .aside-header {
          flex: 0 0 auto;
          padding: $gridaside-mobile-aside-header-padding-y $gridaside-mobile-aside-header-padding-x 0;

          .aside-toggle {
            margin-bottom: 0;
            padding-right: 0;
          }
        }

        .aside-body {
          flex: 0 1 100%;
          overflow: hidden;
          padding: $gridaside-mobile-aside-body-padding-y $gridaside-mobile-aside-body-padding-x;

          .ps {
            max-height: 100%;

            > :nth-last-child(3) {
              margin-bottom: 0;
            }
          }
        }
      }

      .search-count {
        display: block;
        margin-bottom: $gridaside-mobile-search-count-gap;
        font-size: $gridaside-mobile-search-count-font-size;
      }

      &.is-open {
        opacity: 1;
        transform: translateX(0);
        transition: $gridaside-mobile-aside-transition-in;
      }
    }
  }
}
</style>
