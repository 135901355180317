<template>
  <perfect-scrollbar v-bind="scrollbarOptions">
    <slot/>
  </perfect-scrollbar>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  name: 'Scrollbar',
  components: {
    PerfectScrollbar
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    watchOptions: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      scrollbarOptions: {
        // https://github.com/mercs600/vue2-perfect-scrollbar
        tag: this.tag,
        watchOptions: this.watchOptions,
        // https://github.com/mdbootstrap/perfect-scrollbar#options
        options: Object.assign({
          handlers: [
            // 'click-rail',
            // 'drag-thumb',
            'keyboard',
            'wheel',
            'touch'
          ],
          wheelSpeed: 1,
          wheelPropagation: true,
          swipeEasing: true,
          minScrollbarLength: null,
          maxScrollbarLength: null,
          scrollingThreshold: 1000,
          useBothWheelAxes: true,
          suppressScrollX: false,
          suppressScrollY: false,
          scrollXMarginOffset: 0,
          scrollYMarginOffset: 0
        }, this.options)
      }
    }
  }
}
</script>

<style lang="scss">
@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

$scrollbar-rail-gap: 2px !default;
$scrollbar-rail-bg: $gray-200 !default;
$scrollbar-rail-size: 2px !default;
$scrollbar-rail-opacity: 0 !default;
$scrollbar-rail-border-radius: $scrollbar-rail-size !default;
$scrollbar-rail-transition: background-color 200ms linear, opacity 200ms linear !default;

$scrollbar-rail-hover-bg: $scrollbar-rail-bg !default;
$scrollbar-rail-hover-opacity: 1;

$scrollbar-thumb-gap: 0px !default;
$scrollbar-thumb-bg: $primary !default;
$scrollbar-thumb-border-radius: $scrollbar-rail-border-radius !default;
$scrollbar-thumb-transition: background-color 200ms linear, width 200ms ease-in-out, height 200ms ease-in-out !default;

$scrollbar-thumb-hover-bg: $scrollbar-thumb-bg !default;

.ps {
  overflow: visible!important;

  &.ps--active-x {
    overflow: hidden!important;
    padding-bottom: calc(#{$scrollbar-rail-gap} + #{$scrollbar-rail-size});
  }

  &.ps--active-y {
    overflow: hidden!important;
    padding-right: calc(#{$scrollbar-rail-gap} + #{$scrollbar-rail-size});
  }
}

.ps__rail-x,
.ps__rail-y {
  opacity: $scrollbar-rail-opacity;
  border-radius: $scrollbar-rail-border-radius;
  transition: $scrollbar-rail-transition;
}

.ps__rail-x {
  bottom: 0;
  height: $scrollbar-rail-size;

  .ps__thumb-x {
    bottom: $scrollbar-thumb-gap;
    height: calc(100% - #{$scrollbar-thumb-gap * 2});
    background-color: $scrollbar-thumb-bg;
    border-radius: $scrollbar-thumb-border-radius;
    transition: $scrollbar-thumb-transition;
  }

  .ps--active-x > & {
    background-color: $scrollbar-rail-bg;
  }

  .ps:hover > &,
  .ps--focus > &,
  .ps-scrolling-x > & {
    opacity: $scrollbar-rail-hover-opacity;
  }

  .ps &:hover,
  .ps &:focus,
  .ps &.ps--clicking {
    background-color: $scrollbar-rail-hover-bg;
    opacity: $scrollbar-rail-hover-opacity;
  }

  &:hover > .ps__thumb-x,
  &:focus > .ps__thumb-x,
  &.ps--clicking .ps__thumb-x {
    background-color: $scrollbar-thumb-hover-bg;
    height: calc(100% - #{$scrollbar-thumb-gap * 2});
  }
}

.ps__rail-y {
  right: 0;
  width: $scrollbar-rail-size;

  .ps__thumb-y {
    right: $scrollbar-thumb-gap;
    width: calc(100% - #{$scrollbar-thumb-gap * 2});
    background-color: $scrollbar-thumb-bg;
    border-radius: $scrollbar-thumb-border-radius;
    transition: $scrollbar-thumb-transition;
  }

  .ps--active-y > & {
    background-color: $scrollbar-rail-bg;
  }

  .ps:hover > &,
  .ps--focus > &,
  .ps-scrolling-y > & {
    opacity: $scrollbar-rail-hover-opacity;
  }

  .ps &:hover,
  .ps &:focus,
  .ps &.ps--clicking {
    background-color: $scrollbar-rail-hover-bg;
    opacity: $scrollbar-rail-hover-opacity;
  }

  &:hover > .ps__thumb-y,
  &:focus > .ps__thumb-y,
  &.ps--clicking .ps__thumb-y {
    background-color: $scrollbar-thumb-hover-bg;
    width: calc(100% - #{$scrollbar-thumb-gap * 2});
  }
}
</style>
